import { Link as ChakraLink } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import React from 'react'

const CustomLink = ({ children, to = "#", newTab = false, type = "primary", style = {}, title = '' }) => {
    const theme = {
        primary: {
            bg: '#069FFF',
            color: '#FFFFFF',
            hover: '#0a95ed',
            paddingX: '1rem',
            paddingY: '0.6rem',
        },
        secondary: {
            bg: '#A4A4A4',
            color: '#FFFFFF',
            hover: '#898787',
            paddingX: '1rem',
            paddingY: '0.6rem',
        },
        basic: {
            bg: "transparent",
            color: "#333333",
            hover: 'transparent',
            paddingX: 0,
            paddingY: 0,
        }
    }

    return (
        <ChakraLink as={ReactRouterLink} to={to} title={title} target={newTab ? "_blank" : "_self"} padding={"12px 24px"} color={theme[type].color} fontSize={17} fontWeight={600} borderRadius={11} _hover={{ bg: theme[type].hover }} paddingX={theme[type].paddingX} paddingY={theme[type].paddingY} textAlign={'center'} bg={theme[type].bg} style={style}>
            {children}
        </ChakraLink>
    )
}

export default CustomLink